export function getMilesFromMeters(meters: number) {
  return Math.round(meters * 0.000621371192 * 1e2) / 1e2;
}

export function getMilesPerHourFromMetersPerSecond(meters: number) {
  return (Math.round(((meters * 3600) / 1610.3) * 1000) / 1000).toFixed(2);
}

export function getMetersFromMiles(miles: number) {
  return Math.round(miles * 1609.344 * 1e2) / 1e2;
}

export function getKilometersFromMeters(meters: number) {
  return Math.round((meters / 1000) * 1e2) / 1e2;
}

export function getMetersFromKilometers(km: number) {
  return Math.round(km * 1000 * 1e2) / 1e2;
}

export function getMetersFromFeet(feet: number) {
  return Math.round((feet / 3.281) * 1e2) / 1e2;
}

export function getFeetFromMeters(meters: number) {
  return Math.round(meters * 3.281);
}
